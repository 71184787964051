<template>
<div>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <h3 class="card-title">Händlerbestellung</h3>
                <div class="card-tools">
                    <div class="row align-items-center">
                                <div class="col-md-12">
                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  

                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadOrders" :value="search" @input="$store.commit('dealer_orders/changeSearch', $event.target.value)" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadOrders">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </li>

                                            <li class="nav-item">
                                                <button type="button" class="mr-1 btn btn-sm btn-secondary" @click="showSupplierOrderModal" :disabled="selectedOrders.length <= 0" >Lieferantenbestellung</button>       
                                            </li>

                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="btn btn-sm btn-secondary mr-1" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                            </li>
            
                                            <!-- <li class="nav-item d-none d-md-block">
                                                <router-link class="btn btn-primary btn-sm" :to="{name: 'dealer-orders.create'}" v-if="$auth.check('dealer_orders.create')"><i class="fas fa-fw fa-truck"></i> Neue Händlerbestellung</router-link>
                                            </li> -->
                                            
                                        </ul>
                    
                                    </nav>
                                </div>
                            </div>
                    
                    
                    
                    
                </div>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-sm">
                        <thead>
                            <th><input type="checkbox" v-model="selectAll" /></th>
                            <th>
                                <a href="#" @click.prevent="changeSort('ordernumber')">Bestellnummer</a>
                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                            </th>
                            <th>Händler</th>
                            <th>Datum</th>
                            <th>Status</th>
                            <th>Aktion</th>
                        </thead>

                        <tbody>
                            <tr v-for="(order,index) in orders.data" :key="order.id">
                                <td><input type="checkbox" :value="order.id" v-model="selectedOrders"/></td>
                                <td>{{ order.ordernumber }}</td>
                                <td>{{ order.dealer.name }}</td>
                                <td>{{ order.created_at | formatDate}}</td>
                                <td>
                                    <!-- <span class="badge" :class="setStatusBadge(order.status.description)">{{ order.status.description }}</span> -->

                                    <div v-if="selectedRow[index] && selectedCell === 'order_status'" class="input-group">
                                        <select class="form-control form-control-sm" v-model="orders.data[index].status_id" @change="changeStatus(index)" v-if="$auth.check('dealer_orders.edit')">
                                            <option v-for="state in orderStates" :key="state.id" :value="state.id">{{ state.description }}</option> 
                                        </select>
                                        <div class="input-group-append">
                                            <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('order', index)"><i class="fas fa-save"></i></button> -->
                                            <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'order_status')"><i class="fas fa-times"></i></button>
                                        </div>
                                    </div>
                                    <span @dblclick="handleEditCell(index, 'order_status')" v-else class="badge" :class="setStatusBadge(order.status.description)">{{ order.status.description }}</span>

                                </td>
                                
                                <td>
                                    <button type="button" class="btn btn-primary btn-xs mr-1" v-if="order.blocked != 1" @click="downloadPicklist(order)"><i class="fas fa-fw fa-download"></i></button>
                                    <!-- <router-link class="btn mr-1 btn-success btn-xs" :to="{name: 'dealer-orders.show', params: {id: order.id}}" v-if="$auth.check('dealer_orders.show')"><i class="fas fa-fw fa-eye"></i></router-link> -->
                                    <router-link class="mr-1 btn btn-warning btn-xs" v-if="$auth.check('dealer_orders.edit') && order.blocked != 1" :to="{name: 'dealer-orders.details', params: {id: order.id}}"><i class="fas fa-fw fa-edit"></i></router-link>
                                    <button type="button" class="btn btn-danger btn-xs" v-if="$auth.check('dealer_orders.destroy')" @click="deleteOrder(order.id)"><i class="fas fa-fw fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
            <div class="card-footer">
                <template v-if="orders.data.length > 0">
                    <div class="row align-items-center">
                        <div class="col-md-9">
                            <pagination class="float-left" :data="orders" @pagination-change-page="loadOrders" :limit="3"></pagination>
                        </div>
                        <div class="col-md-2">
                            <span class="float-right">Anzeige Eintrag {{ orders.meta.from }} - {{ orders.meta.to }} von {{ orders.meta.total }}</span>
                        </div>
                        <div class="col-md-1">
                            <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                <option value="25">25 Artikel</option>
                                <option value="50">50 Artikel</option>
                                <option value="75">75 Artikel</option>
                                <option value="100">100 Artikel</option>
                                <option value="125">125 Artikel</option>
                                <option value="150">150 Artikel</option>
                            </select>
                        </div>
                    </div>                        
                </template>
            </div>
        </div>
    </b-container>
    <aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
            <h3>Filteroptionen</h3>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Status:</label>
                        <select v-model="params.status_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Status--</option>
                            <option v-for="state in orderStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>

        </div>  
    </aside>

    <b-modal id="offerModal" title="Lieferantenbestellung" size="xl">
        <div class="accordion" id="accordionOffers">
            <div class="card" v-for="(offer, index) in offers" :key="offer.id">
                <div class="card-header" :id="'heading' + offer.id">
                    <h2 class="mb-0">
                        <button class="text-left btn btn-link btn-block" type="button" data-toggle="collapse" :data-target="'#collapse' + offer.id" aria-expanded="true" :aria-controls="'collapse' + offer.id">
                        {{ offer.name }}
                        </button>
                    </h2>
                </div>

                <div :id="'collapse' + offer.id" class="collapse" :aria-labelledby="'heading' + offer.id" data-parent="#accordionOffers">
                    <div class="card-body">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <th>Bestellnummer</th>
                                <th>Artikelname</th>
                                <th>Menge</th>
                            </thead>

                            <tbody>
                                <tr v-for="(item,index2) in offer.items" :key="item.article_id">
                                    <td>{{ item.ordernumber }}</td>
                                    <td>{{ generateName(index, index2) }}</td>
                                    <td>{{ item.quantity }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <template #modal-footer="{ cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="secondary" @click="cancel()">
                Abbrechen
            </b-button>            
        </template>
    </b-modal>
</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'dealer-orders.index',
    title: 'Lieferantenbestellungen',

    data() {
        return {
            'orders': {
                data: [],
            },
            selectedOrders: [],
            selectedRow: {},
            selectedCell: null,
            orderStates: [],
            offers: {},
        }
    },

    computed:{
        selectAll: {
            get: function () {
                return this.orders.data ? this.selectedOrders.length == this.orders.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.orders.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedOrders = selected;
            }
        },
        ...mapState('dealer_orders', ['params', 'search', 'page'])
    },

    watch: {
        params: {
            handler () {
                this.loadOrders();
            },
            deep: true
        },
    },

    methods: {
        generateName(index, index2)
        {
            var article = this.offers[index].items[index2];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.option.name;
            });

            return name;
            
        },

        showSupplierOrderModal(){
            this.axios
                .post("/dealer-orders/offer", {
                    orders: this.selectedOrders,
                })
                .then((response) => {
                    this.offers = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });

                this.$bvModal.show('offerModal');

            
        },

        resetFilter(){
            this.params.status_id = '';
            this.params.per_page = 25;
        },

        changeStatus(order)
        {
            var orders = [this.orders.data[order].id];
            // var status = this.offerStates.find(state => state.id == this.offers.data[offer].status_id);
            this.axios
                .post("/dealer-orders/changeStatus", {
                    orders: orders,
                    status_id: this.orders.data[order].status_id,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Status erfolgreich geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.handleEditCell(order, 'order_status')
                    this.loadOrders();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getOrderStates(){
            this.axios
                .get("/states/dealer-order")
                .then((response) => {
                    this.orderStates = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }, 

        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

        deleteOrder(id){
            this.$swal({
                    title: "Möchtest du die Händlerbestellung wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/dealer-orders/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Bestellung gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                this.loadOrders();
                            })
                            .catch((error) => {
                                var message = 'Da ist etwas schief gelaufen'; 
                                if('error' in error.response.data)
                                {
                                    message = error.response.data.error; 
                                }
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: message, 
                                });
                            });
                    }
                });
        },

        resetSearch(){
            this.$store.commit('dealer_orders/changeSearch', '');
            this.loadOrders();
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        setStatusBadge(state){

            if(state == 'Offen')
            {
                return 'badge-danger'
            }

            if(state == 'In Bearbeitung')
            {
                return 'badge-warning'
            }

            if(state == 'Kommissioniert')
            {
                return 'badge-secondary'
            }

            if(state == 'Abgerechnet')
            {
                return 'badge-success'
            }

            if(state == 'Storniert')
            {
                return 'badge-dark'
            }

            return 'badge-primary';
        },

        downloadPicklist(order)
        {
            this.axios
                .get("/dealer-orders/" + order.id + "/download", {responseType: 'arraybuffer'})
                .then((response) => {
                    //this.orders = response.data.data;
                    this.downloadFile(response, order)
                    this.$Progress.finish();
                    this.loadOrders();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        downloadFile(response, order){
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = 'Bestellschein-' + order.ordernumber + '.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
    },


        loadOrders(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/dealer-orders",{
                    params:{
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.orders = response.data;
                    this.$store.commit('dealer_orders/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created() {
        this.loadOrders();
        this.getOrderStates();
    }
}
</script>

<style>

</style>